import { jobsConstants } from "../Jobs.constants";
import { UncontrolledTooltip } from "reactstrap";
import { SchemaRowInformation } from "./ScheduleJobs";
import Grid from "@mui/material/Unstable_Grid2";
import { CDataAutocomplete } from "@cdatacloud/component-library";

type SchemeOption = {
  value: boolean;
  schemeName: string;
  schemeDescription: string;
  row: SchemaRowInformation;
};

type TimeCheckOption = {
  value: string;
  label: string;
  row: SchemaRowInformation;
};

interface ICacheTablesBody {
  rows: SchemaRowInformation[];
  setRows: (rows: SchemaRowInformation[]) => void;
}

const CacheTablesBody = ({ rows, setRows }: ICacheTablesBody) => {
  function updateFullStatus(newValue: SchemeOption) {
    const { row, value } = newValue;
    const rowToUpdate = rows.find(
      (b) => b.schemaName === row.schemaName && b.tableName === row.tableName,
    )!;
    rowToUpdate.isFullUpdate = value;
    rowToUpdate.timeCheckColumn =
      !value &&
      !rowToUpdate.checkColumnSupport &&
      rowToUpdate.columnOptions?.length === 1
        ? rowToUpdate.columnOptions[0]
        : "";
    setRows([...rows]);
  }

  function updateColumnSelection(newValue: TimeCheckOption) {
    const { row, value } = newValue;

    const rowToUpdate = rows.find(
      (b) => b.schemaName === row.schemaName && b.tableName === row.tableName,
    )!;
    rowToUpdate.timeCheckColumn = value;
    setRows([...rows]);
  }

  const bodyRows = rows?.map((row: SchemaRowInformation, index: any) => {
    const cacheSchemeDisabled =
      !row.columnOptions || row.columnOptions.length === 0;
    const cacheSchemeId = `scheme${index}`;
    const timeCheckDisabled =
      row.isFullUpdate === true ||
      (!row.checkColumnSupport && row.columnOptions?.length === 1);
    const timeCheckId = `timeCheck${index}`;

    const schemeOptions: SchemeOption[] = [
      {
        value: false,
        schemeName: "Incremental Updates",
        schemeDescription: jobsConstants.INCREMENTAL_UPDATES_INFO,
        row: row,
      },
      {
        value: true,
        schemeName: "Full Updates",
        schemeDescription: jobsConstants.FULL_UPDATES_INFO,
        row: row,
      },
    ];

    const getTimeCheckOptions = (): TimeCheckOption[] => {
      return (
        row?.columnOptions?.map((optionName: string) => {
          return { value: optionName, label: optionName, row: row };
        }) ?? []
      );
    };
    const timeCheckOptions = getTimeCheckOptions();

    const getTimeCheckValue = () => {
      if (!timeCheckDisabled)
        return timeCheckOptions?.find((option) => option.row === row) ?? null;

      if (row.isFullUpdate || (!row.isFullUpdate && row.checkColumnSupport)) {
        return null;
      } else {
        return timeCheckOptions[0];
      }
    };

    const getSchemeLabel = (option: SchemeOption) => {
      return (
        <>
          <span className="cache-tables-scheme-first-line">
            {option.schemeName}
          </span>
          <span className="cache-tables-scheme-second-line">
            {option.schemeDescription}
          </span>
        </>
      );
    };

    return (
      <>
        <Grid xs={1} className="cache-tables-cell">
          {row.schemaName + "." + row.tableName}
        </Grid>
        <Grid xs={1} className="cache-tables-cell">
          <span id={cacheSchemeId} data-testid={cacheSchemeId}>
            <CDataAutocomplete<SchemeOption>
              disableWholeSelector={cacheSchemeDisabled}
              options={schemeOptions}
              dropdownLabel={(option) => getSchemeLabel(option)}
              getOptionLabel={(option) => option.schemeName}
              handleChange={(_event, newOption) => updateFullStatus(newOption)}
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
              }}
              selectedValue={
                schemeOptions.find(
                  (option) => option.value === row.isFullUpdate,
                ) ?? null
              }
            />
          </span>
        </Grid>
        {cacheSchemeDisabled ? (
          <UncontrolledTooltip target={cacheSchemeId}>
            {jobsConstants.FULL_UPDATES_ONLY}
          </UncontrolledTooltip>
        ) : null}
        <Grid xs={1} className="cache-tables-cell">
          <span id={timeCheckId} data-testid={timeCheckId}>
            <CDataAutocomplete<TimeCheckOption>
              disableWholeSelector={timeCheckDisabled}
              dropdownLabel={(option) => option.label ?? ""}
              getOptionLabel={(option) => option.label ?? ""}
              handleChange={(_event, newColumn) =>
                updateColumnSelection(newColumn)
              }
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
              }}
              options={timeCheckOptions}
              selectedValue={getTimeCheckValue()}
            />
          </span>
        </Grid>
        {timeCheckDisabled ? (
          !row.isFullUpdate ? (
            <UncontrolledTooltip target={timeCheckId}>
              {jobsConstants.TIME_CHECK_COLUMN_SELECTED}
            </UncontrolledTooltip>
          ) : (
            <UncontrolledTooltip target={timeCheckId}>
              {jobsConstants.TIME_CHECK_NOT_REQUIRED}
            </UncontrolledTooltip>
          )
        ) : null}
        <Grid xs={3} className="cache-tables-divider-row" />
      </>
    );
  });

  return (
    <div className="cache-tables-body">
      <Grid container columns={3}>
        <Grid xs={1} className="cache-tables-header-cell">
          Name{" "}
        </Grid>
        <Grid xs={1} className="cache-tables-header-cell">
          Cache Scheme
        </Grid>
        <Grid xs={1} className="cache-tables-header-cell">
          Time Check Column
        </Grid>
        <Grid xs={3} className="cache-tables-divider-row" />
        {bodyRows}
      </Grid>
    </div>
  );
};

export default CacheTablesBody;
